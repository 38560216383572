<template>
    <div class="total">
        <div class="lef">
            <p class="topTxt">
                <span class="num">{{info.total}}</span>
                <span class="unit">台</span>
            </p>
            <p class="txt">设备总数</p>
            <img src="../../../assets/images/icon_device@2x.png"/>
        </div>
        <div class="rig">
            <div class="online item">
                <img src="../../../assets/images/icon_online@2x.png"/>
                <div class="align">
                    <p class="blue">
                        <span class="num">{{info.onLineNum}}</span>
                        <span class="unit">台</span>
                    </p>
                    <p class="txt">在线设备</p>
                </div>

            </div>
            <div class="offline item">
                <img src="../../../assets/images/icon_offline@2x.png"/>
                <div class="align">
                    <p class="red">
                        <span class="num">{{info.offLine}}</span>
                        <span class="unit">台</span>
                    </p>
                    <p class="txt">离线设备</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getDeviceStatistics} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String
    },
    data() {
        return {
            info: {}
        }
    },
    methods: {
    },
    mounted() {
        if (!this.syncId) return
        getDeviceStatistics({syncId: this.syncId}).then(res => {
            this.info = res.data
        })
    },
}
</script>

<style lang="less">
.total{
    margin-top: 1em;
    display: flex;
    align-items: center;
    height: 90%;
    .lef{
        width: 19%;
        // height: 90%;
        position: relative;
        margin-left: 10%;
        img {
            width: 100%;
            height: auto;
        }
        .topTxt {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            text-align: center;
            .num{
                font-size: 1.3em;
                color: #1B7EF2;
            }
            .unit{
                font-size: 0.9em;
                color: #1B7EF2;
            }
        }
        .txt{
            position: absolute;
            left: 0;
            bottom: -16px;
            width: 100%;
            text-align: center;
            color:  #fff;
            font-size: 0.9em;
        }
    }
    .rig{
        width: 44%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
        align-items: center;
        margin-left: 10%;
        .item {
            position: relative;
            img{
                width: 100%;
                height: auto;
            }
        }
        .align{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 30%;
            padding-top: 0.5em;
            .blue{
                .num{
                    font-size: 1.2em;
                    color: #3EF3FF;
                }
                .unit{
                    font-size: 0.7em;
                    color: #3EF3FF;
                    padding-left: 2px;
                }
            }
            .red{
                .num{
                    font-size: 1.2em;
                    color: #FF4A22;
                }
                .unit{
                    font-size: 0.7em;
                    color: #FF4A22;
                    padding-left: 2px;
                }
            }
            .txt{
                color: #fff;
                padding-left: 20%;
                font-size: 0.8em;
            }
        }
    }
}
</style>